import { useRuntimeConfig } from '#app'

// key/value memory store cache, no expiration
const cache: Record<string, string> = {}
const isDev = process.env.NODE_ENV !== 'production'

interface Params {
  url: string
  baseUrl?: string
  cacheKey?: string
}

export async function fetchWithCache<T>({
  url,
  baseUrl,
  cacheKey,
}: Params): Promise<T> {
  const key = cacheKey || url
  if (cache[key]) {
    return JSON.parse(cache[key]) as T
  }
  if (!baseUrl) {
    try {
      baseUrl = useRuntimeConfig().axios?.baseURL
    } catch (_) {
      // fails if not running in setup
      baseUrl = process.server ? process.env.NUXT_AXIOS_BASE_URL : '/'
    }
  }
  if (isDev) console.log('fetchWithCache.fetching', url)
  const data = await $fetch<T>(url, {
    baseURL: baseUrl,
    timeout: 30000,
  })
  cache[key] = JSON.stringify(data)
  return data as T
}
